<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 2048 2048">
    <path :stroke="stroke" :fill="fill"
          d="M2048 1088H250l787 787l-90 90L6 1024L947 83l90 90l-787 787h1798z"></path>
  </svg>
</template>

<script>
export default {
  name: 'LeftIcon',
  props: {
    stroke: {
      type: String,
      default: 'black'
    },
    fill: {
      type: String,
      default: 'black'
    }
  }
}
</script>
